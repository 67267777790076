// plugins/sentry.client.ts
import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin(nuxtApp => {
  const { sentryDSN, sentryEnvironment } = useEnv()
  if (!sentryDSN || sentryEnvironment === 'local') {
    return
  }

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: sentryDSN,
    environment: sentryEnvironment,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration()
    ],

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ['localhost', 'https://your-server.com'],
    // Adjust sampling rates based on your needs
    tracesSampleRate: 0.1, // Capture 10% of transactions
    replaysSessionSampleRate: 0.05, // Capture 5% of user sessions
    replaysOnErrorSampleRate: 0.2, // Capture replays for 20% of errors

    beforeSend (event, hint) {
      // Check if it is an exception, and if so, log it.
      if (event.exception) {
        // eslint-disable-next-line no-console
        console.error(
          `[Exeption handled by Sentry]: (${hint.originalException})`,
          { event, hint }
        )
      }
      // Continue sending to Sentry
      return event
    }
  })

})
