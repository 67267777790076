import { default as _91slug_93HsR8wN9caqMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/[vertical]/[slug].vue?macro=true";
import { default as index0M4NUc1kwYMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/[vertical]/companies/index.vue?macro=true";
import { default as guidesnTyh55fVaHMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/[vertical]/guides.vue?macro=true";
import { default as _91response_93lnylzAxC6iMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/[vertical]/schedule-callback/[response].vue?macro=true";
import { default as indexC3TM79h8xZMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/[vertical]/schedule-callback/index.vue?macro=true";
import { default as schedule_45callback5LLB1mKiN6Meta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/[vertical]/schedule-callback.vue?macro=true";
import { default as about_45uszQfq49V0JlMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/about-us.vue?macro=true";
import { default as callbackuA9KfwAzXcMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/authorization/callback.vue?macro=true";
import { default as merge9XArlv2UhtMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/authorization/merge.vue?macro=true";
import { default as buyer_45protection_45claimaQOB5q8Hz5Meta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/buyer-protection-claim.vue?macro=true";
import { default as terms_45and_45conditionszt7gVfRSMBMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/buyer-protection/terms-and-conditions.vue?macro=true";
import { default as comprehensiveisVKPSwsIjMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/car-insurance/comprehensive.vue?macro=true";
import { default as indexMHt0wdaK21Meta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/car-insurance/index.vue?macro=true";
import { default as _91step_93Dj7lyrN0yWMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/car-insurance/quote/form/[step].vue?macro=true";
import { default as details3htX1Y9KYOMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/car-insurance/quote/form/details.vue?macro=true";
import { default as resultszHA6aWDJqKMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/car-insurance/quote/form/results.vue?macro=true";
import { default as indexxU5HOi7Xq6Meta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/car-loans/index.vue?macro=true";
import { default as indexBDi087sadvMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/careers/index.vue?macro=true";
import { default as collection_45notice3sbuH7lJ0gMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/collection-notice.vue?macro=true";
import { default as complaintsDKQGmBL3n9Meta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/complaints.vue?macro=true";
import { default as contact_45usllw0LQEe4qMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/contact-us.vue?macro=true";
import { default as _91slug_93Vy46iIVmcaMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/credit-cards/[slug].vue?macro=true";
import { default as indexYJvmgMVn7fMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/credit-cards/index.vue?macro=true";
import { default as _91slug_93IDEkAz0P56Meta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/credit-cards/preview/[slug].vue?macro=true";
import { default as _91slug_93ANqQGmAiSkMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/credit-cards/redirect/[slug].vue?macro=true";
import { default as account_45settingshlOcWJUzOCMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/dashboard/account-settings.vue?macro=true";
import { default as index3yw1Iy6kIgMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/dashboard/index.vue?macro=true";
import { default as claim_45offerjLNzHmkZbRMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/dashboard/offers/[vertical]/claim-offer.vue?macro=true";
import { default as indexXmC8hKMunxMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/dashboard/onboarding/index.vue?macro=true";
import { default as indexot8f0uE4jfMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/dashboard/perks/index.vue?macro=true";
import { default as indexUkAHsbjqybMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/dashboard/quotes/index.vue?macro=true";
import { default as indexizYEcMqHfNMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/dashboard/rewards/index.vue?macro=true";
import { default as additional_45detailsKMayE4hJ56Meta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/dashboard/vault/[[product]]/[vertical]/additional-details.vue?macro=true";
import { default as completionuk5GZUHkXIMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/dashboard/vault/[[product]]/[vertical]/completion.vue?macro=true";
import { default as detailsvEctEFZyoYMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/dashboard/vault/[[product]]/details.vue?macro=true";
import { default as _91step_93pbM53uZBmCMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/dashboard/vault/[[product]]/form/energy/step/[step].vue?macro=true";
import { default as _91step_93IDCcGqviZ0Meta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/dashboard/vault/[[product]]/form/generic/step/[step].vue?macro=true";
import { default as _91step_93AVxI24EFcgMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/dashboard/vault/[[product]]/form/health-insurance/step/[step].vue?macro=true";
import { default as _91step_93IBiEvFbuGQMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/dashboard/vault/[[product]]/form/home-loan/step/[step].vue?macro=true";
import { default as _91step_93jP9RYXvX1pMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/dashboard/vault/[[product]]/form/life-insurance/step/[step].vue?macro=true";
import { default as lic_45detailsvEsM1C5TXpMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/dashboard/vault/[[product]]/lic-details.vue?macro=true";
import { default as rebateyjn65P4B8JMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/dashboard/vault/[[product]]/rebate.vue?macro=true";
import { default as digital_45eicK4grheQRxrMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/energy/application/digital-eic.vue?macro=true";
import { default as errorR7Dw1m06DyMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/energy/application/error.vue?macro=true";
import { default as thank_45youaidf1xHl1NMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/energy/application/thank-you.vue?macro=true";
import { default as indexe6kPgAB6CgMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/energy/index.vue?macro=true";
import { default as _91step_935cTF5TLKPYMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/energy/quote/form/[step].vue?macro=true";
import { default as detailsSiv0WyUkryMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/energy/quote/form/details.vue?macro=true";
import { default as thank_45you2QAQD9m0f1Meta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/energy/quote/thank-you.vue?macro=true";
import { default as _91slug_93uPnrcyTJCZMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/expert-analysis/[category]/[slug].vue?macro=true";
import { default as indexZoAkC9xkwaMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/expert-analysis/[category]/index.vue?macro=true";
import { default as _91author_93tBruUSTZWQMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/expert-analysis/author/[author].vue?macro=true";
import { default as indexMQ1nYTTgSUMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/expert-analysis/index.vue?macro=true";
import { default as forgot_45password60P8j6Jn6wMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/forgot-password.vue?macro=true";
import { default as _91step_93t74XpiPfT4Meta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/health-insurance/application/[step].vue?macro=true";
import { default as expireddRbaBGnAj0Meta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/health-insurance/application/payment/expired.vue?macro=true";
import { default as indexxkbCVixxdJMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/health-insurance/application/payment/index.vue?macro=true";
import { default as indexPQqxOjkEjSMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/health-insurance/application/thankyou/index.vue?macro=true";
import { default as error0YqCAmtPtuMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/health-insurance/error.vue?macro=true";
import { default as indexDvT0l7DCbmMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/health-insurance/index.vue?macro=true";
import { default as live_45chat_45bW6HVvmZzFQMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/health-insurance/live-chat-b.vue?macro=true";
import { default as live_45chatcovLKEjZWOMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/health-insurance/live-chat.vue?macro=true";
import { default as indexYr0iuXw4ezMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/health-insurance/offers/index.vue?macro=true";
import { default as addHbV2TXVlG9Meta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/health-insurance/partners/add.vue?macro=true";
import { default as signupNUkQHLruQ1Meta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/health-insurance/partners/signup.vue?macro=true";
import { default as post_45saleqQ1yHAZZyzMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/health-insurance/post-sale.vue?macro=true";
import { default as indexszyxlMY1U3Meta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/health-insurance/quote/customer/index.vue?macro=true";
import { default as _91step_93xw2BLioKQeMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/health-insurance/quote/form/[step].vue?macro=true";
import { default as detailsgAvBJ7GU5wMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/health-insurance/quote/form/details.vue?macro=true";
import { default as step09dFaf6Y7ziMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/health-insurance/quote/form/step0.vue?macro=true";
import { default as indexYvkSYUDHEeMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/health-insurance/quote/resume/index.vue?macro=true";
import { default as thank_45youvQJxjqed9sMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/health-insurance/quote/thank-you.vue?macro=true";
import { default as indexJOQFymHgz6Meta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/health-insurance/quotes-results/index.vue?macro=true";
import { default as indexjXCppqdssGMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/health-insurance/rate-rise-calculator/index.vue?macro=true";
import { default as indexyzbnTNcZvRMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/health-insurance/students-offer/[university]/form/index.vue?macro=true";
import { default as indexJiHvzvhM9vMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/health-insurance/students-offer/[university]/terms-conditions/index.vue?macro=true";
import { default as _91step_93OpP5yJZG1JMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/[step].vue?macro=true";
import { default as _91step_93auMjjCWV71Meta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/application/[step].vue?macro=true";
import { default as confirmation5QyXEshKRqMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/confirmation.vue?macro=true";
import { default as detailsgBKbo61vAXMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/details.vue?macro=true";
import { default as indexw0BzzsmxpDMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/index.vue?macro=true";
import { default as quotes_45resultsVY7QlNp15wMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/quotes-results.vue?macro=true";
import { default as thank_45youaNI88Rb5CmMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/thank-you.vue?macro=true";
import { default as _91type_93PIl9GxhbyBMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/home-loans/calculators/[type].vue?macro=true";
import { default as indexREP47JdvHiMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/home-loans/calculators/index.vue?macro=true";
import { default as indexnxPUdnzRYIMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/home-loans/index.vue?macro=true";
import { default as interest_45rates3NRLsOGlugMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/home-loans/interest-rates.vue?macro=true";
import { default as _91step_93TX7AwoUP6xMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/home-loans/quote/form/[step].vue?macro=true";
import { default as detailsyN8snvVlQIMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/home-loans/quote/form/details.vue?macro=true";
import { default as thank_45youvXyb6KrTpZMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/home-loans/quote/thank-you.vue?macro=true";
import { default as indexEi48qJaZWEMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/index.vue?macro=true";
import { default as existing_45customersUAMQ3ABiY0Meta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/life-insurance/existing-customers.vue?macro=true";
import { default as confirmationXmNa7ULKjWMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/life-insurance/income-protection/quote/confirmation.vue?macro=true";
import { default as detailsQBi7RwF6YmMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/life-insurance/income-protection/quote/details.vue?macro=true";
import { default as indexBltV8PoedbMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/life-insurance/income-protection/quote/index.vue?macro=true";
import { default as indexslxWRpBjOrMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/life-insurance/index.vue?macro=true";
import { default as addbDky2mFfNhMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/life-insurance/partners/add.vue?macro=true";
import { default as thank_45youjA5qzsdc8TMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/life-insurance/partners/thank-you.vue?macro=true";
import { default as indexB4wGb6brnVMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/life-insurance/quote/customer/index.vue?macro=true";
import { default as successwrDOGTPJZPMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/life-insurance/quote/customer/success.vue?macro=true";
import { default as _91step_93Mwrf69mio7Meta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/life-insurance/quote/form/[step].vue?macro=true";
import { default as detailsUy3ZZAr0dvMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/life-insurance/quote/form/details.vue?macro=true";
import { default as step0NmcK1dDmHVMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/life-insurance/quote/form/step0.vue?macro=true";
import { default as logingCwlUuNLoKMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/login.vue?macro=true";
import { default as _91slug_93bqnHIjh11LMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/media/[slug].vue?macro=true";
import { default as _91author_93jtLSChcxMEMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/media/author/[author].vue?macro=true";
import { default as indexevZSBO3VCIMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/media/index.vue?macro=true";
import { default as partnershipsSzcky8jur9Meta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/partnerships.vue?macro=true";
import { default as indexqs1Ew4wVNYMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/personal-loans/index.vue?macro=true";
import { default as _91step_93TX6dVcDj5kMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/personal-loans/quote/form/[step].vue?macro=true";
import { default as detailsJJpeiIDmtaMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/personal-loans/quote/form/details.vue?macro=true";
import { default as thank_45youRF0KhwBQNYMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/personal-loans/quote/thank-you.vue?macro=true";
import { default as post_45sale_45thank_45youU2ZJxs6RDRMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/post-sale-thank-you.vue?macro=true";
import { default as _91slug_93y5oBbS0M2oMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/preview/[vertical]/[slug].vue?macro=true";
import { default as _91slug_930Z9l3xe79nMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/preview/expert-analysis/[vertical]/[slug].vue?macro=true";
import { default as _91slug_93PKcPYnuUI8Meta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/preview/media/[slug].vue?macro=true";
import { default as privacy_45policyr01xDrulAUMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/privacy-policy.vue?macro=true";
import { default as registerBFzrwwSizQMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/register.vue?macro=true";
import { default as reset_45passwordzZzVR9yLj6Meta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/reset-password.vue?macro=true";
import { default as terms_45of_45useuIGcqtETVUMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/terms-of-use.vue?macro=true";
import { default as verifyQNUpjow9gpMeta } from "/codebuild/output/src2657109901/src/packages/nuxt/pages/verify.vue?macro=true";
export default [
  {
    name: "vertical-slug",
    path: "/:vertical()/:slug()",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/[vertical]/[slug].vue")
  },
  {
    name: "vertical-companies",
    path: "/:vertical()/companies",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/[vertical]/companies/index.vue")
  },
  {
    name: "vertical-guides",
    path: "/:vertical()/guides",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/[vertical]/guides.vue")
  },
  {
    name: schedule_45callback5LLB1mKiN6Meta?.name,
    path: "/:vertical()/schedule-callback",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/[vertical]/schedule-callback.vue"),
    children: [
  {
    name: "vertical-schedule-callback-response",
    path: ":response()",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/[vertical]/schedule-callback/[response].vue")
  },
  {
    name: "vertical-schedule-callback",
    path: "",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/[vertical]/schedule-callback/index.vue")
  }
]
  },
  {
    name: "about-us",
    path: "/about-us",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/about-us.vue")
  },
  {
    name: "authorization-callback",
    path: "/authorization/callback",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/authorization/callback.vue")
  },
  {
    name: "authorization-merge",
    path: "/authorization/merge",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/authorization/merge.vue")
  },
  {
    name: "buyer-protection-claim",
    path: "/buyer-protection-claim",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/buyer-protection-claim.vue")
  },
  {
    name: "buyer-protection-terms-and-conditions",
    path: "/buyer-protection/terms-and-conditions",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/buyer-protection/terms-and-conditions.vue")
  },
  {
    name: "car-insurance-comprehensive",
    path: "/car-insurance/comprehensive",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/car-insurance/comprehensive.vue")
  },
  {
    name: "car-insurance",
    path: "/car-insurance",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/car-insurance/index.vue")
  },
  {
    name: "car-insurance-quote-form-step",
    path: "/car-insurance/quote/form/:step()",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/car-insurance/quote/form/[step].vue")
  },
  {
    name: "car-insurance-quote-form-details",
    path: "/car-insurance/quote/form/details",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/car-insurance/quote/form/details.vue")
  },
  {
    name: "car-insurance-quote-form-results",
    path: "/car-insurance/quote/form/results",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/car-insurance/quote/form/results.vue")
  },
  {
    name: "car-loans",
    path: "/car-loans",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/car-loans/index.vue")
  },
  {
    name: "careers",
    path: "/careers",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/careers/index.vue")
  },
  {
    name: "collection-notice",
    path: "/collection-notice",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/collection-notice.vue")
  },
  {
    name: "complaints",
    path: "/complaints",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/complaints.vue")
  },
  {
    name: "contact-us",
    path: "/contact-us",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/contact-us.vue")
  },
  {
    name: "credit-cards-slug",
    path: "/credit-cards/:slug()",
    meta: _91slug_93Vy46iIVmcaMeta || {},
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/credit-cards/[slug].vue")
  },
  {
    name: "credit-cards",
    path: "/credit-cards",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/credit-cards/index.vue")
  },
  {
    name: "credit-cards-preview-slug",
    path: "/credit-cards/preview/:slug()",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/credit-cards/preview/[slug].vue")
  },
  {
    name: "credit-cards-redirect-slug",
    path: "/credit-cards/redirect/:slug()",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/credit-cards/redirect/[slug].vue")
  },
  {
    name: "dashboard-account-settings",
    path: "/dashboard/account-settings",
    meta: account_45settingshlOcWJUzOCMeta || {},
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/dashboard/account-settings.vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: index3yw1Iy6kIgMeta || {},
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/dashboard/index.vue")
  },
  {
    name: "dashboard-offers-vertical-claim-offer",
    path: "/dashboard/offers/:vertical()/claim-offer",
    meta: claim_45offerjLNzHmkZbRMeta || {},
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/dashboard/offers/[vertical]/claim-offer.vue")
  },
  {
    name: "dashboard-onboarding",
    path: "/dashboard/onboarding",
    meta: indexXmC8hKMunxMeta || {},
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/dashboard/onboarding/index.vue")
  },
  {
    name: "dashboard-perks",
    path: "/dashboard/perks",
    meta: indexot8f0uE4jfMeta || {},
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/dashboard/perks/index.vue")
  },
  {
    name: "dashboard-quotes",
    path: "/dashboard/quotes",
    meta: indexUkAHsbjqybMeta || {},
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/dashboard/quotes/index.vue")
  },
  {
    name: "dashboard-rewards",
    path: "/dashboard/rewards",
    props: indexizYEcMqHfNMeta?.props ?? {"verificationNeeded":true},
    meta: indexizYEcMqHfNMeta || {},
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/dashboard/rewards/index.vue")
  },
  {
    name: "dashboard-vault-product-vertical-additional-details",
    path: "/dashboard/vault/:product?/:vertical()/additional-details",
    meta: additional_45detailsKMayE4hJ56Meta || {},
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/dashboard/vault/[[product]]/[vertical]/additional-details.vue")
  },
  {
    name: "dashboard-vault-product-vertical-completion",
    path: "/dashboard/vault/:product?/:vertical()/completion",
    meta: completionuk5GZUHkXIMeta || {},
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/dashboard/vault/[[product]]/[vertical]/completion.vue")
  },
  {
    name: "dashboard-vault-product-details",
    path: "/dashboard/vault/:product?/details",
    meta: detailsvEctEFZyoYMeta || {},
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/dashboard/vault/[[product]]/details.vue")
  },
  {
    name: "dashboard-vault-product-form-energy-step-step",
    path: "/dashboard/vault/:product?/form/energy/step/:step()",
    meta: _91step_93pbM53uZBmCMeta || {},
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/dashboard/vault/[[product]]/form/energy/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-generic-step-step",
    path: "/dashboard/vault/:product?/form/generic/step/:step()",
    meta: _91step_93IDCcGqviZ0Meta || {},
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/dashboard/vault/[[product]]/form/generic/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-health-insurance-step-step",
    path: "/dashboard/vault/:product?/form/health-insurance/step/:step()",
    meta: _91step_93AVxI24EFcgMeta || {},
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/dashboard/vault/[[product]]/form/health-insurance/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-home-loan-step-step",
    path: "/dashboard/vault/:product?/form/home-loan/step/:step()",
    meta: _91step_93IBiEvFbuGQMeta || {},
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/dashboard/vault/[[product]]/form/home-loan/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-life-insurance-step-step",
    path: "/dashboard/vault/:product?/form/life-insurance/step/:step()",
    meta: _91step_93jP9RYXvX1pMeta || {},
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/dashboard/vault/[[product]]/form/life-insurance/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-lic-details",
    path: "/dashboard/vault/:product?/lic-details",
    meta: lic_45detailsvEsM1C5TXpMeta || {},
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/dashboard/vault/[[product]]/lic-details.vue")
  },
  {
    name: "dashboard-vault-product-rebate",
    path: "/dashboard/vault/:product?/rebate",
    meta: rebateyjn65P4B8JMeta || {},
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/dashboard/vault/[[product]]/rebate.vue")
  },
  {
    name: "energy-application-digital-eic",
    path: "/energy/application/digital-eic",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/energy/application/digital-eic.vue")
  },
  {
    name: "energy-application-error",
    path: "/energy/application/error",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/energy/application/error.vue")
  },
  {
    name: "energy-application-thank-you",
    path: "/energy/application/thank-you",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/energy/application/thank-you.vue")
  },
  {
    name: "energy",
    path: "/energy",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/energy/index.vue")
  },
  {
    name: "energy-quote-form-step",
    path: "/energy/quote/form/:step()",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/energy/quote/form/[step].vue")
  },
  {
    name: "energy-quote-form-details",
    path: "/energy/quote/form/details",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/energy/quote/form/details.vue")
  },
  {
    name: "energy-quote-thank-you",
    path: "/energy/quote/thank-you",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/energy/quote/thank-you.vue")
  },
  {
    name: "expert-analysis-category-slug",
    path: "/expert-analysis/:category()/:slug()",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/expert-analysis/[category]/[slug].vue")
  },
  {
    name: "expert-analysis-category",
    path: "/expert-analysis/:category()",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/expert-analysis/[category]/index.vue")
  },
  {
    name: "expert-analysis-author-author",
    path: "/expert-analysis/author/:author()",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/expert-analysis/author/[author].vue")
  },
  {
    name: "expert-analysis",
    path: "/expert-analysis",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/expert-analysis/index.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45password60P8j6Jn6wMeta || {},
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/forgot-password.vue")
  },
  {
    name: "health-insurance-application-step",
    path: "/health-insurance/application/:step()",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/health-insurance/application/[step].vue")
  },
  {
    name: "health-insurance-application-payment-expired",
    path: "/health-insurance/application/payment/expired",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/health-insurance/application/payment/expired.vue")
  },
  {
    name: "health-insurance-application-payment",
    path: "/health-insurance/application/payment",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/health-insurance/application/payment/index.vue")
  },
  {
    name: "health-insurance-application-thankyou",
    path: "/health-insurance/application/thankyou",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/health-insurance/application/thankyou/index.vue")
  },
  {
    name: "health-insurance-error",
    path: "/health-insurance/error",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/health-insurance/error.vue")
  },
  {
    name: "health-insurance",
    path: "/health-insurance",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/health-insurance/index.vue")
  },
  {
    name: "health-insurance-live-chat-b",
    path: "/health-insurance/live-chat-b",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/health-insurance/live-chat-b.vue")
  },
  {
    name: "health-insurance-live-chat",
    path: "/health-insurance/live-chat",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/health-insurance/live-chat.vue")
  },
  {
    name: "health-insurance-offers",
    path: "/health-insurance/offers",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/health-insurance/offers/index.vue")
  },
  {
    name: "health-insurance-partners-add",
    path: "/health-insurance/partners/add",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/health-insurance/partners/add.vue")
  },
  {
    name: "health-insurance-partners-signup",
    path: "/health-insurance/partners/signup",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/health-insurance/partners/signup.vue")
  },
  {
    name: "health-insurance-post-sale",
    path: "/health-insurance/post-sale",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/health-insurance/post-sale.vue")
  },
  {
    name: "health-insurance-quote-customer",
    path: "/health-insurance/quote/customer",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/health-insurance/quote/customer/index.vue")
  },
  {
    name: "health-insurance-quote-form-step",
    path: "/health-insurance/quote/form/:step()",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/health-insurance/quote/form/[step].vue")
  },
  {
    name: "health-insurance-quote-form-details",
    path: "/health-insurance/quote/form/details",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/health-insurance/quote/form/details.vue")
  },
  {
    name: "health-insurance-quote-form-step0",
    path: "/health-insurance/quote/form/step0",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/health-insurance/quote/form/step0.vue")
  },
  {
    name: "health-insurance-quote-resume",
    path: "/health-insurance/quote/resume",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/health-insurance/quote/resume/index.vue")
  },
  {
    name: "health-insurance-quote-thank-you",
    path: "/health-insurance/quote/thank-you",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/health-insurance/quote/thank-you.vue")
  },
  {
    name: "health-insurance-quotes-results",
    path: "/health-insurance/quotes-results",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/health-insurance/quotes-results/index.vue")
  },
  {
    name: "health-insurance-rate-rise-calculator",
    path: "/health-insurance/rate-rise-calculator",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/health-insurance/rate-rise-calculator/index.vue")
  },
  {
    name: "health-insurance-students-offer-university-form",
    path: "/health-insurance/students-offer/:university()/form",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/health-insurance/students-offer/[university]/form/index.vue")
  },
  {
    name: "health-insurance-students-offer-university-terms-conditions",
    path: "/health-insurance/students-offer/:university()/terms-conditions",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/health-insurance/students-offer/[university]/terms-conditions/index.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-step",
    path: "/health-insurance/visitors-visa-ovhc/:step()",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/[step].vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-application-step",
    path: "/health-insurance/visitors-visa-ovhc/application/:step()",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/application/[step].vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-confirmation",
    path: "/health-insurance/visitors-visa-ovhc/confirmation",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/confirmation.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-details",
    path: "/health-insurance/visitors-visa-ovhc/details",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/details.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc",
    path: "/health-insurance/visitors-visa-ovhc",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/index.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-quotes-results",
    path: "/health-insurance/visitors-visa-ovhc/quotes-results",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/quotes-results.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-thank-you",
    path: "/health-insurance/visitors-visa-ovhc/thank-you",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/thank-you.vue")
  },
  {
    name: "home-loans-calculators-type",
    path: "/home-loans/calculators/:type()",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/home-loans/calculators/[type].vue")
  },
  {
    name: "home-loans-calculators",
    path: "/home-loans/calculators",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/home-loans/calculators/index.vue")
  },
  {
    name: "home-loans",
    path: "/home-loans",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/home-loans/index.vue")
  },
  {
    name: "home-loans-interest-rates",
    path: "/home-loans/interest-rates",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/home-loans/interest-rates.vue")
  },
  {
    name: "home-loans-quote-form-step",
    path: "/home-loans/quote/form/:step()",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/home-loans/quote/form/[step].vue")
  },
  {
    name: "home-loans-quote-form-details",
    path: "/home-loans/quote/form/details",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/home-loans/quote/form/details.vue")
  },
  {
    name: "home-loans-quote-thank-you",
    path: "/home-loans/quote/thank-you",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/home-loans/quote/thank-you.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/index.vue")
  },
  {
    name: "life-insurance-existing-customers",
    path: "/life-insurance/existing-customers",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/life-insurance/existing-customers.vue")
  },
  {
    name: "life-insurance-income-protection-quote-confirmation",
    path: "/life-insurance/income-protection/quote/confirmation",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/life-insurance/income-protection/quote/confirmation.vue")
  },
  {
    name: "life-insurance-income-protection-quote-details",
    path: "/life-insurance/income-protection/quote/details",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/life-insurance/income-protection/quote/details.vue")
  },
  {
    name: "life-insurance-income-protection-quote",
    path: "/life-insurance/income-protection/quote",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/life-insurance/income-protection/quote/index.vue")
  },
  {
    name: "life-insurance",
    path: "/life-insurance",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/life-insurance/index.vue")
  },
  {
    name: "life-insurance-partners-add",
    path: "/life-insurance/partners/add",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/life-insurance/partners/add.vue")
  },
  {
    name: "life-insurance-partners-thank-you",
    path: "/life-insurance/partners/thank-you",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/life-insurance/partners/thank-you.vue")
  },
  {
    name: "life-insurance-quote-customer",
    path: "/life-insurance/quote/customer",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/life-insurance/quote/customer/index.vue")
  },
  {
    name: "life-insurance-quote-customer-success",
    path: "/life-insurance/quote/customer/success",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/life-insurance/quote/customer/success.vue")
  },
  {
    name: "life-insurance-quote-form-step",
    path: "/life-insurance/quote/form/:step()",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/life-insurance/quote/form/[step].vue")
  },
  {
    name: "life-insurance-quote-form-details",
    path: "/life-insurance/quote/form/details",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/life-insurance/quote/form/details.vue")
  },
  {
    name: "life-insurance-quote-form-step0",
    path: "/life-insurance/quote/form/step0",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/life-insurance/quote/form/step0.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: logingCwlUuNLoKMeta || {},
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/login.vue")
  },
  {
    name: "media-slug",
    path: "/media/:slug()",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/media/[slug].vue")
  },
  {
    name: "media-author-author",
    path: "/media/author/:author()",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/media/author/[author].vue")
  },
  {
    name: "media",
    path: "/media",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/media/index.vue")
  },
  {
    name: "partnerships",
    path: "/partnerships",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/partnerships.vue")
  },
  {
    name: "personal-loans",
    path: "/personal-loans",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/personal-loans/index.vue")
  },
  {
    name: "personal-loans-quote-form-step",
    path: "/personal-loans/quote/form/:step()",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/personal-loans/quote/form/[step].vue")
  },
  {
    name: "personal-loans-quote-form-details",
    path: "/personal-loans/quote/form/details",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/personal-loans/quote/form/details.vue")
  },
  {
    name: "personal-loans-quote-thank-you",
    path: "/personal-loans/quote/thank-you",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/personal-loans/quote/thank-you.vue")
  },
  {
    name: "post-sale-thank-you",
    path: "/post-sale-thank-you",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/post-sale-thank-you.vue")
  },
  {
    name: "preview-vertical-slug",
    path: "/preview/:vertical()/:slug()",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/preview/[vertical]/[slug].vue")
  },
  {
    name: "preview-expert-analysis-vertical-slug",
    path: "/preview/expert-analysis/:vertical()/:slug()",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/preview/expert-analysis/[vertical]/[slug].vue")
  },
  {
    name: "preview-media-slug",
    path: "/preview/media/:slug()",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/preview/media/[slug].vue")
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/privacy-policy.vue")
  },
  {
    name: "register",
    path: "/register",
    meta: registerBFzrwwSizQMeta || {},
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/register.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: reset_45passwordzZzVR9yLj6Meta || {},
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/reset-password.vue")
  },
  {
    name: "terms-of-use",
    path: "/terms-of-use",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/terms-of-use.vue")
  },
  {
    name: "verify",
    path: "/verify",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/verify.vue")
  },
  {
    name: "Guides",
    path: "/credit-cards/guides",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/[vertical]/guides.vue")
  },
  {
    name: "Companies",
    path: "/credit-cards/companies",
    component: () => import("/codebuild/output/src2657109901/src/packages/nuxt/pages/[vertical]/companies/index.vue")
  }
]