import { createInstance } from '@optimizely/optimizely-sdk'

declare global {
  // eslint-disable-next-line no-unused-vars
  interface window {
    optimizely: any
  }
}

export default defineNuxtPlugin({
  name: 'optimizely',
  parallel: true,
  setup () {
    class Optimizely {
      optimizelyLoaded: boolean = false
      instance: any = null

      async init () {
        if (import.meta.client) {
          // Load Optimizely script
          await this.addOptimizelyScript()

          /*
            * Sets optimizely details, and generates optimizely data
            * if optimzely has already been initialized.
            *
            * This is trigged in app.vue route watch
           */
          if (this.optimizelyLoaded) {
            this.setOptimizelyDetails()
            await useUtmsStore().GENERATE_OPTIMIZELY_DATA()
          }
        }
      }

      addOptimizelyScript () {
        const { optimizelyScript } = useRuntimeConfig().public

        const optimizelyScriptEl = document.getElementById('optimizely-script')

        if (!optimizelyScriptEl) {
          useHead({
            script: [{
              src: `${optimizelyScript}`,
              type: 'text/javascript',
              id: 'optimizely-script',
              async: true,
              defer: true,
              onload: async () => {
                await this.createOptimizelyInstance()
                this.setOptimizelyDetails()
                await useUtmsStore().GENERATE_OPTIMIZELY_DATA()
              }
            }]
          })
        }
      }

      async createOptimizelyInstance () {
        const { optimizelySdkKey } = useRuntimeConfig().public

        this.instance = await createInstance({
          sdkKey: optimizelySdkKey,
          eventBatchSize: 100,
          eventFlushInterval: 1000,
          odpOptions: {
            disabled: true
          }
        })

        await this.instance.onReady()
          .then(() => {
            this.optimizelyLoaded = true
          })
      }

      setOptimizelyDetails () {
        useUtmsStore().optimizely_utms = {
          optimizely_visitor_id: window.optimizely?.get('visitor_id'),
          optimizely_active_experiment_ids: window.optimizely?.get('state').getActiveExperimentIds(),
          optimizely_variation_map: window.optimizely?.get('state').getVariationMap()
        }
      }
    }

    const optimizelyClient = new Optimizely()

    return {
      provide: {
        optimizelyClient
      }
    }
  }
})
