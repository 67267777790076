/*
 | =================================================
 | This File is for member hub vaults state handling
 | =================================================
 */

const state = {
  vaultFormData: {},
  products: [],
  product: {},
  lifeProduct: {},
  archivedProducts: [],
  vaultLoader: true,
  setManualHealthVaultActive: false,
  isSyncing: false,
  inlineEditParams: {},
  isInlineEdit: false,
  leadAddress: {},
  verticals: getLocal('verticals')
    ? JSON.parse(getLocal('verticals'))
    : []
}

export default () => {
  return state
}
