import AuthService from '~/server/service/auth.service'
import memberhubConstants from '~/constants/memberhub.constants'
import { VERTICAL } from '~/utils/constants/verticals.constants'

const authService = new AuthService()

const actions = {
  loader (status = true) {
    const layoutStore = useLayoutsStore()
    layoutStore.setPageLoader(status)
  },
  /*
     * Checking user exists or not
     * @param email
     * @returns {Promise<unknown>}
     */
  async checkUserExistence (email) {
    this.loader(true)
    const res = await authService.userExistenceCheck(email)
    return checkResponse(res, false)
      .then(response => {
        return Promise.resolve(response.authentication)
      })
      .catch(error => {
        return Promise.reject(error)
      })
      .finally(() => {
        this.loader(false)
      })
  },
  /*
     * User registration store action
     * @param payload
     * @returns {Promise<unknown>}
     */
  async register (payload) {
    const { $tracking } = useNuxtApp()
    this.loader(true)
    const r = await authService.register(payload.formData)
    return checkResponse(r, false, payload.schema)
      .then(async response => {
        this.setSignUpData(payload.formData)
        $tracking.customEvent('My Account Sign up', { emailHashed: response.data?.hash_id })
        await this.login({ ...payload, ...{ preventRedirect: true } })
          .finally(() => {
            this.loader(false)
          })
        return Promise.resolve(response.data)
      })
      .catch(error => {
        this.loader(false)
        return Promise.reject(error)
      })
  },
  /*
     * User login store action
     * @param context
     * @param payload
     * @returns {Promise<unknown>}
     */
  async login (payload) {
    const { $tracking, $bus } = useNuxtApp()
    this.loader(true)
    const r = await authService.login(payload.formData)
    return checkResponse(r, false, payload.schema)
      .then(response => {
        this.setToken(response.data)
        this.setIsPurchasedSignUp(payload.psSignUp?payload.psSignUp:false)
        return this.profile()
          .then(async data => {
            if (import.meta.client) {
              sessionStorage.setItem('user_logged_in', true)
              $tracking.login({ emailHashed: data.hash_id })
            }
            let isOnboarding = !data.profile?.onboardingCompleted && !data.profile?.onboardingSkipped
            await nextTick()
            if (Object.keys(this.tmpProfile).length && isOnboarding) {
              const onBoardingFormData = {}
              if (this.tmpProfile.postcode && this.tmpProfile.dateOfBirth && this.tmpProfile.household) {
                if (this.tmpProfile.postcode === 'I don\'t live in Australia yet.') {
                  onBoardingFormData.postcode = ''
                  onBoardingFormData.state = ''
                  onBoardingFormData.suburb = ''
                } else {
                  onBoardingFormData.postcode = this.tmpProfile.postcode
                  onBoardingFormData.state = this.tmpProfile.state
                  onBoardingFormData.suburb = this.tmpProfile.suburb
                }
                if (this.tmpProfile.household === 'Prefer not to say') {
                  onBoardingFormData.household = ''
                } else {
                  onBoardingFormData.household = this.tmpProfile.household
                }
                /*
                 * Pre-set products
                 * @type {(string|number|string[]|[{label: string, value: string},{label: string, value: string},{label: string, value: string}]|*)[]}
                 */
                onBoardingFormData.productInterest = [
                  VERTICAL.HEALTH_INSURANCE,
                  VERTICAL.LIFE_INSURANCE,
                  VERTICAL.ENERGY,
                  VERTICAL.HOME_LOANS
                ]
                onBoardingFormData.dateOfBirth = this.tmpProfile.dateOfBirth
                onBoardingFormData.onboardingCompleted = true
                isOnboarding = false
              }
              await this.saveUserProfileDetails({ formData: onBoardingFormData })
            }
            await nextTick()
            if (!payload.preventRedirect) {
              $bus.emit('redirection',
                {
                  path: (isOnboarding ? '/dashboard/onboarding/' : (this.getAuthUserSourcePage || '/dashboard/')),
                  query: this.getAuthUserSourcePageQuery || {}
                }
              )
            }
          })
      })
      .catch(error => {
        return Promise.reject(error)
      })
      .finally(() => {
        this.loader(false)
      })
  },
  /*
     * User Profile store action
     * @param context
     * @param payload
     * @returns {Promise<unknown>}
     */
  async profile (payload) {
    const { $bus } = useNuxtApp()
    const r = await authService.profile(payload ? payload.formData : {})
    return checkResponse(r, false)
      .then(response => {
        /*
         * "logged-in" event -> For One-tap support
         * "authenticated" event -> global logged in watcher
         */
        $bus.emit('logged-in')
        this.setProfile(response.data)
        return Promise.resolve(response.data)
      })
      .catch(error => {
        return Promise.reject(error)
      })
  },
  /*
     * User profile mutation
     * @param profile
     */
  setProfile (profile) {
    setLocal('auth_user', JSON.stringify(profile))
    this.userProfile = profile
  },
  /*
     * Auth token mutation
     * @param token
     */
  setToken (token) {
    setLocal('token', JSON.stringify(token))
    this.token = token
  },

  /*
    * Auth token mutation
    * @param token
    */
  setIsPurchasedSignUp (psSignup) {
    this.psSignUp = psSignup
  },

  /*
     * Signup data mutation
     * @param payload
     */
  setSignUpData (payload) {
    this.signUpData = payload
  },
  /*
     * Initializing social authentication store action
     * @param context
     * @param payload
     * @returns {Promise<T>}
     */
  async socialAuthInit (payload) {
    this.loader(true)
    let r = null
    payload.state = this.defaultAuthState
    // eslint-disable-next-line default-case
    switch (payload.social_auth_type) {
      case memberhubConstants.GOOGLE_AUTH:
        r = await authService.googleAuthentication(payload)
        break
      case memberhubConstants.FACEBOOK_AUTH:
        r = await authService.facebookAuthentication(payload)
        break
      case memberhubConstants.LINKEDIN_AUTH:
        r = await authService.linkedinAuthentication(payload)
        break
    }
    if (r) {
      return checkResponse(r, false)
        .then(response => {
          if (import.meta.client && response.data.url) {
            location.replace(response.data.url)
            return Promise.resolve(response.data)
          }
          return Promise.reject(response)
        })
        .catch(error => {
          this.loader(false)
          return Promise.reject(error)
        })
    }
    this.loader(false)
    return Promise.reject(r)
  },
  /*
     * Finishing social authentication store action
     * @param context
     * @param payload
     * @returns {Promise<any>}
     */
  async finishSocialAuthentication (payload) {
    const { $tracking, $bus } = useNuxtApp()
    const r = await authService.submitSocialAuth(payload.formData)
    return checkResponse(r, false)
      .then(response => {
        this.setToken(response.data)
        /*
                 * Differentiating source page url and sso url for proper auth tracking
                 * @type {string}
                 */
        let sourcePageUrl = ''
        let sourcePageQuery = ''
        let ssoUrl = ''
        let ssoPath = ''
        let sourcePagePath = ''
        if (import.meta.client && sessionStorage.getItem(payload.formData.state)) {
          const sessionData = JSON.parse(sessionStorage.getItem(payload.formData.state))
          if (sessionData.source_page_url) {
            sourcePageUrl = sessionData.source_page_url
          }
          if (sessionData.sso_url) {
            ssoUrl = sessionData.sso_url
          }
          if (sessionData.query) {
            sourcePageQuery = sessionData.query
          }
          if (sessionData.sso_path) {
            ssoPath = sessionData.sso_path
          }
          if (sessionData.source_page_path) {
            sourcePagePath = sessionData.source_page_path
          }
          if (sessionData.utms) {
            sourcePageQuery = { ...sourcePageQuery, ...sessionData.utms }
          }
        }
        nextTick().then(() => {
          if (response.data.is_signup) {
            $tracking.customEvent('My Account Sign up', {
              emailHashed: response.data?.hash_id,
              sourcePageUrl,
              ssoUrl,
              sourcePagePath,
              ssoPath
            })
          }
          return this.profile()
            .then(data => {
              if (import.meta.client) {
                sessionStorage.setItem('user_logged_in', true)
                if (payload.formData.state) {
                  sessionStorage.setItem('social_auth', true)
                }
                $tracking.login({ emailHashed: data.hash_id, sourcePageUrl, ssoUrl, sourcePagePath, ssoPath })
              }
              const isOnboarding = !data.profile?.onboardingCompleted
              const sourcePageUrlParts = new URL(sourcePageUrl)
              $bus.emit('redirection', {
                path: (isOnboarding ? '/dashboard/onboarding/' : ((memberhubConstants.AUTH_ROUTES.includes(trailingSlash(sourcePageUrlParts?.pathname)) ? '/dashboard/' : `${trailingSlash(sourcePageUrlParts?.pathname)}`) || '/dashboard')),
                query: sourcePageQuery || {}
              })
              nextTick().then(() => {
                if (import.meta.client && payload.formData.state) {
                  sessionStorage.removeItem(payload.formData.state)
                }
              })
            }).finally(() => {
              this.loader(false)
            })
        })
      })
      .catch(error => {
        if (error.code === memberhubConstants.API_MULTIPLE_CHOICES) {
          $bus.emit('redirection', {
            path: '/authorization/merge/',
            query: { url: error?.data?.data?.url || error?.data?.url || '',user_email: error?.data?.data?.user_email || error?.data?.user_email || '' }
          })
        } else {
          $bus.emit('redirection', { path: '/login' })
        }
        this.loader(false)
        return Promise.reject(error)
      })
  },
  /*
     * Forgot password
     * @param payload
     * @returns {Promise<T>}
     */
  async forgotPassword (payload) {
    this.loader(true)
    const r = await authService.forgotPassword(payload.formData)
    return checkResponse(r, true, payload.schema)
      .then(response => {
        return Promise.resolve(response.data)
      })
      .catch(error => {
        return Promise.reject(error)
      })
      .finally(() => {
        this.loader(false)
      })
  },
  /*
     * Password reset store action
     * @param context
     * @param payload
     * @returns {Promise<unknown>}
     */
  async resetPassword (payload) {
    this.loader(true)
    const r = await authService.resetPassword(payload.url, payload.formData)
    return checkResponse(r, true, payload.schema)
      .then(response => {
        return Promise.resolve(response.data)
      })
      .catch(error => {
        return Promise.reject(error)
      })
      .finally(() => {
        this.loader(false)
      })
  },
  /*
     * Merge User accounts store action
     * @param context
     * @param payloadWithUrl
     * @returns {Promise<any>}
     */
  async mergeUserAccounts (payload) {
    const { $bus } = useNuxtApp()
    const res = await authService.merge(payload.url, payload.formData)
    return checkResponse(res, false)
      .then(() => {
        return this.socialAuthInit(payload)
      })
      .catch(error => {
        $bus.emit('redirection', { path: '/login' })
        return Promise.reject(error)
      })
  },
  /*
     * User logout store action
     * @param context
     * @returns {Promise<void>}
     */
  // eslint-disable-next-line require-await
  async logout () {
    authService.logout()
    return this.forceLogout()
  },
  /*
     * Force logout store action
     * @param context
     * @returns {Promise<void>}
     */
  // eslint-disable-next-line require-await
  async forceLogout () {
    const { $toast } = useNuxtApp()
    this.clearAuthUser()
    $toast.success('Successfully logged out.')
    return Promise.resolve()
  },
  /*
     * Clear auth user mutation
     */
  clearAuthUser () {
    const layoutStore = useLayoutsStore()
    const vaultStore = useVaultStore()
    const quoteStore = useQuotesStore()
    this.userProfile = {}
    this.token = {}
    this.onBoardingFormData = {}
    removeLocal('token')
    removeLocal('auth_user')
    removeLocal('verticals')
    layoutStore.resetPageLoader()
    vaultStore.resetProductList()
    quoteStore.quotes = []
    if (import.meta.client) {
      sessionStorage.removeItem('user_logged_in')
    }
  },
  /*
     * Mutator for onboarding formData
     * @param state
     * @param payload
     */
  setOnBoardingFormData (payload) {
    this.onBoardingFormData = { ...this.onBoardingFormData, ...payload }
  },

  /*
     * User details updating  store action
     * @param context
     * @param payload
     * @returns {Promise<unknown>}
     */
  async saveUserProfileDetails (payload) {
    this.loader(true)
    const r = await authService.updateProfile(payload.formData)
    return checkResponse(r, payload.hasOwnProperty('showToast')?payload.showToast:true, payload.schema)
      .then(response => {
        this.setProfile(response.data)
        return Promise.resolve(response.data)
      })
      .catch(error => {
        return Promise.reject(error)
      })
      .finally(() => {
        this.loader(false)
      })
  },
  /*
     * User email verification link resend store action
     * @returns {Promise<unknown>}
     */
  async resendVerification () {
    this.loader(true)
    const r = await authService.resendVerification()
    return checkResponse(r, true)
      .then(response => {
        return Promise.resolve(response.data)
      })
      .catch(error => {
        return Promise.reject(error)
      })
      .finally(() => {
        this.loader(false)
      })
  },
  /*
     * Verification process store action with api url
     * @param context
     * @param callBackUrl
     * @returns {Promise<unknown>}
     */
  async verifyUserDirectly (callBackUrl) {
    this.loader(true)
    const r = await authService.verifyUser(callBackUrl)
    return checkResponse(r, true)
      .then(response => {
        this.profile()
          .then(() => {
            return Promise.resolve(response.data)
          })
          .catch(error => {
            return Promise.reject(error)
          })
          .finally(() => {
            this.loader(false)
          })
      })
      .catch(error => {
        return Promise.reject(error)
      })
      .finally(() => {
        this.loader(false)
      })
  },
  /*
     * Mutation for reset User profile data
     * @param state
     */
  resetProfile () {
    const profile = getLocal('auth_user')
    this.userProfile = profile ? JSON.parse(profile) : {}
  },
  /*
     * Google one tap auth init store action
     * @param context
     * @param payload
     * @returns {Promise<T>}
     */
  async googleOneTapAuthInit (payload) {
    const res = await authService.googleOneTapAuthentication(payload)
    return checkResponse(res, false)
      .then(response => {
        if (import.meta.client && response.data.url) {
          location.replace(response.data.url)
          return Promise.resolve(response.data)
        }
        return Promise.reject(response)
      })
      .catch(error => {
        return Promise.reject(error)
      })
  },

  /*
     * Get user details store action
     * @returns {Promise<unknown>}
     */
  async getUserDetails () {
    const res = await authService.userDetails()
    return checkResponse(res, false)
      .then(response => {
        if (import.meta.client && response.data.user_id) {
          return Promise.resolve(response.data)
        }
        return Promise.reject(response)
      })
      .catch(error => {
        return Promise.reject(error)
      })
  }
}

export default actions
